/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';
import voltoGoogleAnalytics from 'volto-google-analytics';
import eeacmsvoltoWidgetToggle from '@eeacms/volto-widget-toggle';

const addonsInfo = {"volto-form-block":{"name":"volto-form-block","version":"2.0.2","isPublishedPackage":true,"modulePath":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-form-block/src","packageJson":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-form-block/package.json","addons":["volto-subblocks"]},"volto-google-analytics":{"name":"volto-google-analytics","version":"1.3.0","isPublishedPackage":true,"modulePath":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-google-analytics/src","packageJson":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-google-analytics/package.json","addons":[]},"@eeacms/volto-widget-toggle":{"name":"@eeacms/volto-widget-toggle","version":"2.2.6","isPublishedPackage":true,"modulePath":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/@eeacms/volto-widget-toggle/src","packageJson":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/@eeacms/volto-widget-toggle/package.json","addons":[]},"volto-subblocks":{"name":"volto-subblocks","version":"1.0.1","isPublishedPackage":true,"modulePath":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-subblocks/src","packageJson":"/var/lib/zope5.2.py3/volto/wmc.volto/2/node_modules/volto-subblocks/package.json","addons":[]}};
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [voltoSubblocks, voltoFormBlock, voltoGoogleAnalytics, eeacmsvoltoWidgetToggle];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
